// Enum definitions
export enum ModeEnum {
    EDITING = "editing",
    EXPECTS = "expects",
    PROCESSING = "processing",
    OUTPUT = "output",
    IMPROVE = "improve",
    IMPROVING = "improving",
}

export enum TypeEnum {
    INSTRUCTION = "instruction",
    INPUTS = "inputs",
    MARKDOWN = "markdown",
    IMAGE = "image",
    CHAT = "chat",
    NOTE = "note",
    WEBPAGES = "webpages",
    FILES = "files",
    OBJECT = "object",
    QUERY = "query"
}

export enum StatusEnum {
    SUCCESS = "success",
    PENDING = "pending",
    RUNNING = "running",
    RESPONDING="responding",
    ABORTED = "aborted",
    FAILED = "failed"
}

export enum KindEnum {
    SIMPLE = "simple",
    AUTOMATED = "automated",
    V1= "v1"
}

export enum Stage {
    LIVE= "live",
    TEST="test",
}

// Base BaseStep interface
export interface BaseStep {
    step_id?: string;
    description?: string;
    type: TypeEnum;
    mode: ModeEnum;
    partial: boolean;
    error?: string;
}

// Specific BaseStep types extending base BaseStep
export interface MarkdownStep extends BaseStep {
    markdown: string;
}

export interface InstructionStep extends BaseStep {
    instruction: string;
    markdown?: string;
    mute: boolean;
    pinned: boolean;
    feedback?: string;
}

export interface WebpagesStep extends BaseStep {
    urls: string[];
    metadata_webpages?: { [key: string]: any };
    processed_webpages?: { [key: string]: string };
    reload?: boolean;
}

export interface FilesStep extends BaseStep {
    files: string[];
    processed_files?: { [key: string]: string };
    metadata_files?: { [key: string]: any };
    reload?: boolean;
}

export interface NoteStep extends BaseStep {
    markdown: string;
}

export interface ImageStep extends BaseStep {
    instruction: string;
    filename?: string;
}

export interface JObjectStep extends BaseStep {
    json_schema: { [key: string]: any };
    json_object?: { [key: string]: any };
}

export interface ChatStep extends BaseStep {
    messages: { [key: string]: any }[];
}

export interface SearchStep extends BaseStep {
    query: string;
    result?: { [key: string]: any };
}

export interface Variable {
    description: string;
    title: string;
    type: string;
    value: string | number;
}

export interface Variables {
    [key: string]: Variable;
}

export interface Inputs {
    description: string;
    sources: Item[];
    mode: ModeEnum;
    use_cached: boolean;
    variables: Variables;
}

export interface WorkflowTemplate {
    kind: KindEnum;
    category: string;
    task: string;
    name: string;
    description: string;
    inputs: Inputs;
    steps: BaseStep[];
}

export interface ChatbotTemplate {
    kind: KindEnum;
    category: string;
    name: string;
    purpose: string;
    inputs: Inputs;    
}



// Workflow interface
export interface Workflow extends WorkflowTemplate {
    project_id: string;
    workflow_id: string;
    status: StatusEnum;
    computed_at?: number;
    pinned: boolean;
    stage: Stage;
    created_at: string;
    updated_at: string;
    persona_id: string;
    archived: boolean;
    run_id?: string;
    account_id?: string;
    model_used?: string;
    start_at?: string;
    end_at?: string;
    current_time?:string;
    run_time?: number;
    version?: number;
    
    

}

export type User = {
    account_id: string;
    name: string;
    email: string;
}

export interface Commands {
    instruct: string[];
    text: string[];
    answered: string[];
    edit: string[];
    question: string[];
}

export interface ApiKey{
    api_key: string;
    reveal: boolean;
    name: string;
}

export interface Usage {
    total_usage: {
      run_time: number;
      input_tokens: number;
      output_tokens: number;
      success: number;
      input_charges: number;
      output_charges: number;
    };
    daily_usage: Array<{
      year?: number;
      month: number;
      day: number;
      usage: {
        run_time: number;
        input_tokens: number;
        output_tokens: number;
        input_charges: number;
        output_charges: number;
      };
    }>;
  }

  export interface Credits {
    credits: number;
  }

  export interface Persona {
    persona_id: string;
    name: string;
    description: string;
    favorite: boolean;
    created_at: string;
    updated_at: string;
    content: any;
}


export interface Document {
    document_id: string;
    name: string;
    content: string;
    created_at: string;
    updated_at: string;
}

export interface Persona {
    persona_id: string;
    name: string;
    content: any;
    description: string;
    favorite: boolean;
    created_at: string;
    updated_at: string;
}

export interface Item {
    item_id?: string;
    name: string;
    type: string;
    source: string;
    description: string;
    created_at?: string
    updated_at?: string;
}


export interface InputVariable {
    title: string;
    description: string;
    type: string;
    value: any;
}

export interface ChatbotInputs {
    sources: Item[];
    mode: string;
    use_cached: boolean;
    variables: { [key: string]: InputVariable };
    description: string;
}

export interface BackgroundKnowledge {
    description: string;
    mode: ModeEnum;
    sources: Item[];
    use_cached: boolean;
}

export enum MessageType {
    EMAIL = "email",
    SLACK = "slack",
    UI = "ui"
}

export interface BaseMessage {
    message_id: string;
    message_type: MessageType;
    sender: string;
    content: string;
    role: string;
    attachments?: { [key: string]: any }[];
    timestamp: string;
    metadata: { [key: string]: any };
    error?: string;
    computed_at?: number;
    partial: boolean;
}

export interface EmailMessage extends BaseMessage {
    sender: string; // EmailStr in TypeScript
    recipients: string[]; // EmailStr[] in TypeScript
    subject: string;
    cc?: string[]; // EmailStr[] in TypeScript
    bcc?: string[]; // EmailStr[] in TypeScript
    importance: string;
}

export interface SlackMessage extends BaseMessage {
    channel_id: string;
    thread_ts?: string;
    reactions?: { [key: string]: any }[];
    edited?: { [key: string]: any };
}

export interface UIMessage extends BaseMessage {
    // No additional fields for UIMessage
}

export type Message = EmailMessage | SlackMessage | UIMessage;

export interface Chatbot {
    kind: KindEnum;
    project_id: string;
    chatbot_id: string;
    name: string;
    description: string;
    purpose: string;
    instructions: string;
    introduction: string;
    stage:Stage;
    shortlink: string;
    background_knowledge: BackgroundKnowledge;
    persona_id: string;
    pinned: boolean;
    created_at: string;
    updated_at: string;
    archived: boolean;
}

export interface Conversation {
    kind: KindEnum;
    project_id: string;
    chatbot_id: string;
    conversation_id: string;
    title: string;
    description: string;
    status: StatusEnum;
    inputs: ChatbotInputs;
    messages: Message[];
    persona_id: string;
    computed_at?: number;
    pinned: boolean;
    model_used?: string;
    start_at?: string;
    end_at?: string;
    run_time?: number;
    version?: number;
    current_time: string;
    created_at: string;
    updated_at: string;
    archived: boolean;
}

